<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('my_jobs')"
	                    :isColumns="true"
	                    @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('my_jobs')"
		                      :isColumns="true"
		                      @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter v-if="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       @on-show-detail-button-click="onShowDetailButton($event)"
                       v-show="datatable.showTable"
            ></datatable>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import JobService from "@/services/JobService";
    import qs from 'qs'

    export default {
        components: {
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
        },
        metaInfo() {
            return {
                title: this.$t('my_jobs')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('detail'),
                                    class: 'ri-search-eye-line',
                                    permission: "joblog_show",
                                    callback: (row) => {
                                        this.$router.push('/job/logs/detail/' + row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id'),
                            field: 'id',
                            hidden: false,
                        },
                        {
                            label: this.$t('name'),
                            field: 'name',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('request_data_count'),
                            field: 'request_data_count',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('success_data_count'),
                            field: 'success_data_count',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('failed_data_count'),
                            field: 'failed_data_count',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center',
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                            id: null,
                            name: null,
                        },
                        sort: 'name',
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterSet() {
                this.datatable.queryParams.filter = {
                    id: null,
                    name: null,
                };
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            onShowDetailButton(id) {
                this.$router.push('/job/logs/detail/' + id);
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return JobService.getAll(config)
                                 .then((response) => {
                                     this.datatable.rows = response.data.data
                                     this.datatable.total = response.data.pagination.total
                                 })
                                 .finally(() => {
                                     this.datatable.isLoading = false;
                                 });
            }
        }
    };
</script>

